import React from 'react'
import logo from '../../assets/images/zaven_logo_rgb_color_neg.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub, faGooglePlus, faLinkedin, } from '@fortawesome/fontawesome-free-brands'
import { Link } from 'gatsby'
import axios from "axios"
import { faCheck, faSpinner } from "@fortawesome/pro-solid-svg-icons"

class Footer extends React.Component {
  STATE_READY = 'Subscribe'
  STATE_SENDING = 'Sending...'
  STATE_SENT = 'Subscribed!'
  STATE_ERROR = 'Try again'

  constructor (props) {
    super(props)
    this.state = {
      email: null,
      current: this.STATE_READY,
    }
  }

  render() {
    const { websiteVersion, companyDetails, newsletterUrl } = this.props

    return <footer className="footer bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <div className="footer-newsletter">
              <p>
                <strong>Stay in touch with us!</strong>
                <br />
                <span className="text-muted">Leave us your e-mail to get the latest news and updates</span>
              </p>

              <form ref={r => (this.formRef = r)} action="#" method="post" className="form" name="footer_newsletter_form" id="footer-newsletter-form" onSubmit={event => {
                this.setState({ current: this.STATE_SENDING });
                event.preventDefault();
                axios
                  .post(newsletterUrl, { email: this.state.email })
                  .then(() => {
                    this.setState({ email: null, current: this.STATE_SENT });
                    this.formRef.reset();
                  })
                  .catch(() => {
                    this.setState({ current: this.STATE_ERROR });
                  });
              }}>
                <fieldset className="form-inline">
                  <div className="input-group">
                    <input onChange={e => this.setState({
                      email: e.target.value,
                    })} className="form-control form-control-success" type="email" name="email" placeholder="Your e-mail" id="newsletter" required />
                    <div className="input-group-append">
                      <button type="submit" className="btn btn-outline-primary" name="send_button">
                        {this.state.current === this.STATE_SENDING && <FontAwesomeIcon style={{ marginRight: '0.5rem' }} icon={faSpinner} spin />}
                        {this.state.current === this.STATE_SENT && <FontAwesomeIcon style={{ marginRight: '0.5rem' }} icon={faCheck} />}
                        {this.state.current}
                      </button>
                    </div>
                  </div>
                </fieldset>
                <div className="form-group form-check mt-2">
                  <input type="checkbox" className="form-check-input" id="footer-newsletter-form-gdpr-consent" name="gdpr_consent" required />
                  <label className="form-check-label small text-muted" htmlFor="footer-newsletter-form-gdpr-consent">
                    I agree to receive a newsletter from Zaven Sp. z.o.o. The consent is voluntary and I can withdraw it any time, stopping further processing of my data.
                    </label>
                </div>
                <p className="text-center-xs mb-0" style={{ marginTop: '5px' }}>
                  <small className="text-muted newsletter-hint">&nbsp;</small>
                </p>
              </form>
            </div>
            <hr className="d-block d-sm-none" />
          </div>
          <div className="col-sm-6">
            <div className="text-sm-right text-left">
              <p className="lead">
                <a className="zaven-brand" href="/">
                  <img src={logo} className="zaven-logo" alt="Zaven" />
                </a>
              </p>
              <address>
                <a href={`${companyDetails.address.office.url}`} target="_blank" className="link-unstyled" rel="noopener noreferrer">{`${companyDetails.address.office.street}, ${companyDetails.address.office.city} (${companyDetails.address.office.country})`}</a>
                <br />
                <span className="d-none d-md-inline">
                  <a href="mailto:hello@zaven.co" className="link-unstyled">{`${companyDetails.contact.email}`}</a>, <a href="tel:{{zaven.contact.phone_clean}}" className="link-unstyled">{`${companyDetails.contact.phone}`}</a>
                </span>
              </address>
              <p className="footer-social">
                <a href={companyDetails.linkedin} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} style={{ margin: '0.15rem' }} />
                </a>
                <a href={companyDetails.facebook} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebook} style={{ margin: '0.15rem' }} />
                </a>
                <a href={companyDetails.googleplus} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faGooglePlus} style={{ margin: '0.15rem' }} />
                </a>
                <a href={companyDetails.github} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faGithub} style={{ margin: '0.15rem' }} />
                </a>
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-6">
            <div className="row">
              <div className="col-md-3">
                <nav className="nav d-none d-md-block">
                  <Link to="/">Home</Link>
                  <br />
                  <Link to="/projects">Projects</Link>
                  <br />
                  <a href="https://blog.zaven.co" target="_blank" rel="noopener noreferrer">
                    Blog
                    </a>
                  <br />
                </nav>
              </div>
              <div className="col-md-3">
                <nav className="nav d-none d-md-block">
                  <Link to="/career">Career</Link>
                  <br />
                  <Link to="/contact">Contact</Link>
                  <br />
                  <Link to="/legal">Terms of use</Link>
                  <br />
                </nav>
              </div>
              <div className="col-md-6">
                <nav className="nav d-none d-md-block">
                  <Link to="/services/mobile-app-development-poland">Mobile development</Link>
                  <br />
                  <Link to="/services/web-app-development-poland">Web development</Link>
                  <br />
                  <Link to="/services/poland-software-outsourcing">IT Outsourcing</Link>
                  <br />
                </nav>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <p className="footer-legal text-left text-sm-right">
              v{websiteVersion} © All rights reserved. {companyDetails.legalName}, {companyDetails.address.base.street}, ${companyDetails.address.base.postal} {companyDetails.address.base.city}, VAT-ID {companyDetails.vat} Reg no. {companyDetails.krs}, {companyDetails.krs_sad}, share capital {companyDetails.capital}. Apple Watch® and Swift® are trademarks of Apple Inc.
              </p>
          </div>
        </div>
      </div>
    </footer>;
  }
}

export default Footer
