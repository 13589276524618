import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'gatsby';
class Gdpr extends React.Component {
  
  setCookie = (name, value, days = 30, path = '/') => {
    if(typeof window !== 'undefined') {
      const expires = new Date(Date.now() + days * 864e5).toUTCString()
      document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
    }
  }
  
  acceptAnalytics = () => {
    this.setCookie("zavenGdpr", "accepted");
    this.divRef.classList.remove("gdprShow");
    this.divRef.classList.add("gdprHide");
    this.divRef.classList.remove("position-bottom");
  }

  render() {
    return (
      <div
        ref={r => this.divRef = r}
        id="cookie"
        className="alert gdpr-alert alert-dismissable position-bottom py-4 gdprShow"
      >
        <div className="container">
          <div className="gdpr-body">
            <div className="mr-4 mb-2 text-xs-left">
              <strong>
                We use tracking tools on this website to optimize user
                experience and communication.
              </strong>
              <br />
              To find out more about how we use this data, visit our
              <Link
                id="gdpr-accept-btn"
                to="/legal#privacy-policy"
                className="gdpr-alert-btn"
              >
                {' '}
                Privacy Policy
              </Link>
            </div>
            <div>
              <button
                id="gdpr-accept-btn"
                className="gdpr-alert-btn btn btn-outline-primary"
                style={{ width: 161 }}
                onClick={this.acceptAnalytics}
              >
                <FontAwesomeIcon icon={faCheck} /> Accept analytics
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Gdpr
