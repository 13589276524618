import React from 'react';

const Technologies = ({ listOfTechnologies }) => {
  const renderTechnologiesList = technologies => {
    return technologies.map(technology => (
      <span title={technology.node.title} key={technology.node.title} className="technology" data-toggle="tooltip">
        <img src={technology.node.image.childImageSharp.fluid.src} alt={technology.node.title} />
      </span>
    ));
  };

  return (
    <div className="container technologies-container">
      <div className="row">
        <div className="col-md-12">
          <div className="text-center technologies">{renderTechnologiesList(listOfTechnologies)}</div>
        </div>
      </div>
    </div>
  );
};

export default Technologies;


