import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookSquare, faGooglePlusSquare, faLinkedin } from "@fortawesome/fontawesome-free-brands"
import { faGlobe } from "@fortawesome/pro-solid-svg-icons"

const SingleTestimonial = ({ testimonial }) => {

  const getIcon = (iconName) => {
    switch (iconName) {
      case 'google+':
        return faGooglePlusSquare
      case 'fb':
        return faFacebookSquare
      case 'linkedin':
        return faLinkedin
      default:
        return faGlobe
    }
  }

  return (
    <section id='testimonials-clients' className='testimonials'>
      <div className='col-xs-11 offset-xs-1 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2'>
        <blockquote className="no-icon">{testimonial.message}</blockquote>

        <div className='media'>
          <div className='mr-3'>
            <a href={testimonial.social[0].url} target='_blank' rel="noopener noreferrer">
              <img
                className='rounded-circle avatar'
                src={testimonial.avatar.childImageSharp.fluid.src}
                alt={testimonial.name}
              />
            </a>
          </div>
          <div className='media-body'>
            <p className='m-0'>
              <strong>{testimonial.name}</strong>
            </p>
            <p className='text-sm text-muted'>
              {testimonial.role} at {testimonial.company}
              <span className='d-none d-md-inline social-icons' />
              <br />
              {testimonial.social.map((social, index) =>
                <a key={index}
                  href={social.url}
                  className='link-unstyled'
                  target='_blank'
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={getIcon(social.icon)} style={{ margin: '0.1rem' }} />
                </a>
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SingleTestimonial
