import React from 'react'
import ServiceJumbotron from '../../components/services/ServiceJumbotron'
import NavBar from '../../components/common/NavBar'
import ContactBottom from '../../components/contact/ContactBottom'
import Technologies from '../../components/common/Technologies'
import Divider from '../../components/common/Divider'
import Faq from '../../components/common/Faq'
import MvpReasons from '../../components/services/MvpReasons'
import ServiceWebTags from '../../components/services/web/ServiceWebTags'
import SingleTestimonial from '../../components/services/SingleTestimonial'
import ServiceWebOffer from '../../components/services/web/ServiceWebOffer'
import ServiceWebCta from '../../components/services/web/ServiceWebCta'
import ServiceWebRequirementCta from '../../components/services/web/ServiceWebRequirementCta'
import Helmet from 'react-helmet'
import Layout from '../../layouts';
import { graphql } from "gatsby"

const WebAppDevelopmentPage = ({ data, location }) => {
  const description = data.content_web.description.split('%')
  const {
    title,
    features,
    pageTitle,
    metaDescription,
    keywords,
  } = data.content_web
  const {
    email,
    phone,
    skype,
    phoneClean,
    skypeName,
    workTimeUTC,
  } = data.info_web.contact
  const { city, street, country, url } = data.info_web.address.office
  const technologies = data.technologies_web.edges
  const testimonial = data.testimonials_web
  const { legalName } = data.info_web
  const faqs = data.faq_web.edges.map(e => ({
    question: e.node.question,
    answer: e.node.answer,
    id: e.node.id,
  }))

  const mailingConfig = data.mailing_info_web
  const { servicesIlustrationWeb } = data;

  return (
    <Layout>
      <Helmet
        title={pageTitle}
        meta={[
          { name: 'description', content: metaDescription },
          { name: 'keywords', content: keywords },
        ]}
      />
      <NavBar phoneNumber={phone} email={email} location={location}/>
      <ServiceJumbotron
        colorAccentClass={'service-web'}
        image={servicesIlustrationWeb.fluid.src}
        title={title}
        description={[
          description[0],
          <strong key={'a'} className="seo-regular">{description[1]}</strong>,
          description[2],
          <h3 key={'b'} className="seo-span">{description[3]}</h3>,
          description[4],
          <h3 key={'c'} className="seo-span">{description[5]}</h3>,
        ]}
        infoPackUrl={`${process.env.GATSBY_MAILING_URL}/api/infopack`}
        features={features}
      />
      <ServiceWebOffer />
      <MvpReasons />
      <ServiceWebRequirementCta />
      <ServiceWebTags />
      <ServiceWebCta />
      <Divider />
      <Technologies
        listOfTechnologies={technologies}
        style={{ padding: '3.25rem 0 3.25rem 0' }}
      />
      <Faq listOfFaqs={faqs} />
      <SingleTestimonial testimonial={testimonial} />
      <ContactBottom
        city={city}
        skype={skype}
        street={street}
        country={country}
        legalName={legalName}
        phoneClean={phoneClean}
        workTimeUTC={workTimeUTC}
        skypeName={skypeName}
        phone={phone}
        email={email}
        addressUrl={url}
        mailingConfig={mailingConfig}
      />
    </Layout>
  )
}

export default WebAppDevelopmentPage

export const query = graphql`
  query WebDevPageQuery {
    info_web: dataJson {
      ...Contact
    }
    technologies_web: allTechnologiesJson(
      filter: { displayIn: { eq: "services_web" } }
    ) {
      ...Technologies
    }
    content_web: servicesYaml(title: { eq: "Web app development Poland" }) {
      title
      pageTitle
      metaDescription
      keywords
      description
      features {
        icon
        title
        description
      }
    }
    faq_web: allFaqJson(filter: { displayIn: { eq: "services_web" } }) {
      ...Faqs
    }
    testimonials_web: testimonialsClientsJson(
      displayIn: { eq: "services_web" }
    ) {
      name
      company
      message
      avatar {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      role
      social {
        icon
        url
      }
    }
    servicesIlustrationWeb: imageSharp(fluid: { originalName: { regex: "/services_illustration_web/" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
