import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'

const ServiceWebOffer = () => (
  <section className='service-web'>
    <div className='container'>
      <div className='row py-5'>
        <div className='col-sm-12 col-md-4'>
          <h2 className='text-sm-center text-md-right'>
            <span className='text-muted seo-regular'>Web app outsourcing</span>
            <br />
            development Poland
          </h2>
        </div>
        <div className='col-sm-12 col-md-8'>
          <p>
            Why is it cost-effective to outsource projects to Zaven? Because we
            have successfully worked with clients from different parts of the
            world and we know how to guide them through the whole process:
            vision development, SCRUM consulting, prototyping, UX design, QA
            testing, app development, deployment, RWD, integration, maintenance
            and support.
          </p>
          <p>
            Our near-sourcing team guarantees efficiency and great performance
            with offshore pricing. To learn which model would be perfect for
            you,&nbsp;
            <Link to='/services/poland-software-outsourcing'>
              check our outsourcing guide
            </Link>
            .
          </p>
        </div>
      </div>

      <div className='row pb-5'>
        <div className='col-sm-12 col-md-4'>
          <div className='seo-h2 text-sm-center text-md-right'>
            <span className='text-muted text-regular'>
              <strong className='seo-regular'>Company promise</strong> and
            </span>
            <br />

            <h3 className='seo-span'>
              <strong>software development lifecycle</strong>
            </h3>
          </div>
        </div>
        <div className='col-sm-12 col-md-8'>
          <p>
            In our company we believe that at the base of every long-term
            business partnership lies satisfaction and trust. To make sure that
            our collaboration goes smoothly, we have adopted best practices when
            it comes to software production and implementation.
          </p>
          <ul className='fa-ul'>
            <li>
              <span className='fa-li'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Fully functional and user-friendly solutions
            </li>
            <li>
              <span className='fa-li'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Low costs and great value for money
            </li>
            <li>
              <span className='fa-li'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              High end-user adaptation
            </li>
            <li>
              <span className='fa-li'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              <h4 className='seo-span'>Agile development process</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebOffer
