import React, { Component } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import markerIcon from '../../assets/images/map-marker-icon.png';

export class Map extends Component {
  render() {
    const { location, zoom, marker } = this.props;
    const scaledIcon = {
      scaledSize: { width: 48, height: 48 },
      url: markerIcon,
    };
    const ZavenMap = withScriptjs(
      withGoogleMap(() => (
        <GoogleMap defaultCenter={location} options={MapOptions} defaultZoom={zoom}>
          {marker === null ? (
            <p />
          ) : (
            <Marker
              // animation={google.maps.Animation.DROP}
              position={marker.location}
              draggable={false}
              icon={scaledIcon}
            />
          )}
        </GoogleMap>
      )),
    );

    return (
      <ZavenMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MapOptions.zaven.key}`}
        containerElement={<div style={{ width: this.props.width, height: this.props.height }} />}
        loadingElement={<div style={{ width: this.props.width, height: this.props.height }} />}
        mapElement={<div style={{ width: this.props.width, height: this.props.height }} />}
      />
    );
  }
}

export const MapOptions = {
  zaven: {
    key: 'AIzaSyDp_6N0TKehVO8lmkhlZX0MMVVz4mtH2ak',
    global: { location: { lat: 53, lng: 8 }, zoom: 4 },
    local: { location: { lat: 51.109281, lng: 17.195168 }, zoom: 12 },
    marker: { location: { lat: 51.146281, lng: 17.135168 } },
  },
  mapTypeId: 'roadmap',
  scrollwheel: false,
  panControl: false,
  zoomControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  navigationControl: false,
  overviewMapControl: false,
  streetViewControl: false,
  rotateControl: false,
  scaleControl: false,
  draggable: false,

  styles: [
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }, { lightness: 20 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ffffff' }, { lightness: 17 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }, { lightness: 18 }],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }, { lightness: 16 }],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }, { lightness: 21 }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#dedede' }, { lightness: 21 }],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }],
    },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [{ color: '#fefefe' }, { lightness: 20 }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
    },
  ],
};
