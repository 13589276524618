import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons'
import logo from '../../assets/images/zaven_logo_rgb_color_neg.png'
import logoLight from '../../assets/images/zaven_logo_rgb_color_pos.png'
import { Link } from 'gatsby'
import {
  Container,
  Collapse,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

class NavBar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isAboutMenuOpen: false,
      isServicesMenuOpen: false,
      isNavbarExpanded: false,
    }
  }

  componentDidMount() {
    const { fixedPosition, transparentBackground } = this.props
    // Toggle navbar class if it's transparent and view is scrolled down
    if (fixedPosition && transparentBackground) {
      this.addOnScrollListener()
    }
  }

  componentWillUnmount() {
    this.removeOnScrollListener()
  }

  toggleServicesDropdown = () => {
    this.setState(prevState => ({
      isServicesMenuOpen: !prevState.isServicesMenuOpen,
    }))
  }

  toggleAboutDropdown = () => {
    this.setState(prevState => ({
      isAboutMenuOpen: !prevState.isAboutMenuOpen,
    }))
  }

  toggleNavbarCollapse = () => {
    this.setState(prevState => ({
      isNavbarExpanded: !prevState.isNavbarExpanded,
    }))

    this.state.isNavbarExpanded && this.props.transparentBackground
      ? this.nav.classList.add('navbar-transparent', 'bg-transparent')
      : this.nav.classList.remove('navbar-transparent', 'bg-transparent')
  }

  addOnScrollListener = () => {
    window.addEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    window.pageYOffset > 0
      ? this.nav.classList.add('navbar-scrolled')
      : this.nav.classList.remove('navbar-scrolled')
  }

  removeOnScrollListener = () => {
    window.removeEventListener('scroll', this.onScroll)
  }

  removeOnClickListener = () => {
    window.removeEventListener('click', this.onClick)
  }

  render() {
    const {
      fixedPosition,
      transparentBackground,
      lightTheme,
      phoneNumber,
      email,
      location,
    } = this.props

    const navbarClass = `
      navbar flex-wrap flex-column justify-content-between navbar-expand-md
      ${transparentBackground ? 'navbar-transparent bg-transparent' : ''}
      ${fixedPosition ? 'fixed-top navbar-not-scrolled' : 'navbar-static'}
      ${lightTheme ? 'navbar-light bg-lightest' : 'navbar-dark bg-dark'}
    `

    const dropdownMenuClass = `
      ${lightTheme ? 'bg-light-active' : 'bg-dark'}
    `
    // prevents 'cannot read 'pathname' of undefined' error
    const isActive = (location, type) => {
      return location && location.pathname && location.pathname.includes(type) ? 'active' : '';
    }

    return <div id="navbar-main">
      <nav ref={r => (this.nav = r)} className={navbarClass} style={this.props.style}>
        <Container className="justify-content-center justify-content-sm-end">
          <Nav className="navbar-banner navbar-text">
            <span className="no-wrap">
              <FontAwesomeIcon icon={faPhone} /> <a href={`tel:${phoneNumber}`} className="link-unstyled">
                {phoneNumber}
              </a>
            </span>
            <span className="mr-4" />
            <span className="no-wrap">
              <FontAwesomeIcon icon={faEnvelope} /> <a href={`mailto:${email}`} className="link-unstyled">
                {email}
              </a>
            </span>
          </Nav>
        </Container>
        <Container>
          <div className="navbar-brand w-auto">
            <Link className="zaven-brand text-white mr-1" to="/">
              <img src={lightTheme ? logoLight : logo} className="zaven-logo " alt="Zaven" />
            </Link>
            <em className="d-md-inline d-none">&nbsp;|&nbsp;</em>
            <h1 className="tagline d-md-inline d-none ml-1">Software Development Poland</h1>
          </div>
          <NavbarToggler onClick={this.toggleNavbarCollapse} />
          <Collapse isOpen={this.state.isNavbarExpanded} className="justify-content-md-end" navbar>
            <Nav className="navbar-nav mb-5 mb-sm-0">
              <NavItem>
                <Dropdown isOpen={this.state.isServicesMenuOpen} toggle={this.toggleServicesDropdown} inNavbar className={isActive(location, 'services')}>
                  <DropdownToggle caret tag="a" className="nav-link" style={{ cursor: 'pointer' }}>
                    services
                    </DropdownToggle>
                  <DropdownMenu className={dropdownMenuClass}>
                    <DropdownItem tag={Link} to="/services/mobile-app-development-poland" className="service-mobile" activeClassName="active">
                      Mobile development
                        <br />
                      <em className="text-sm text-muted">Android & iOS apps</em>
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/services/web-app-development-poland" className="service-web" activeClassName="active">
                      Web development
                        <br />
                      <em className="text-sm text-muted">Web apps and services</em>
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/services/poland-software-outsourcing" className="service-outsourcing" activeClassName="active">
                      IT Outsourcing
                        <br />
                      <em className="text-sm text-muted">Scale up your development team</em>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem className={isActive(location, 'projects')}>
                <Link to="/projects" className="nav-link">
                  projects
                  </Link>
              </NavItem>
              <NavItem>
                <Dropdown isOpen={this.state.isAboutMenuOpen} toggle={this.toggleAboutDropdown} inNavbar className={isActive(location, 'about')}>
                  <DropdownToggle caret tag="a" style={{ cursor: 'pointer' }} className="nav-link">
                    about
                    </DropdownToggle>
                  <DropdownMenu className={dropdownMenuClass}>
                    <DropdownItem tag={Link} to="/about/team/" activeClassName="active">
                      Team
                      </DropdownItem>
                    <DropdownItem tag={Link} to="/career/" activeClassName="active">
                      Career
                      </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem>
                <a href="http://blog.zaven.co" target="_blank" className="nav-link" rel="noopener noreferrer">
                  blog
                  </a>
              </NavItem>
              <NavItem className={`ml-md-2 mt-3 mt-sm-0 ${isActive(location, 'contact')}`}>
                <Link to="/contact" id="get-navbar-contact" className={`btn ${lightTheme ? 'btn-warning' : 'btn-outline-warning'}`}>
                  contact us
                  </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </nav>
    </div>;
  }
}



export default NavBar