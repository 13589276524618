import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons'
import axios from 'axios'
import { ReCaptcha } from 'react-recaptcha-v3'
import { faCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import smoothScroll from 'scroll-to-element'

class ContactForm extends React.Component {
  phoneNumberPrefixInputRef = null
  fullPhoneNumberInputRef = null

  STATE_READY = 'Send message'
  STATE_SENDING = 'Sending...'
  STATE_SENT = 'Message sent'
  STATE_ERROR = 'Try again'

  defaultState = {
    phoneInputFocused: false,
    isDummyPhoneInputVisible: true,
    isPhoneInputVisible: false,
    current: this.STATE_READY,
    interestedIn: '',
    numberPrefix: '',
    category: '',
    message: '',
    number: '',
    email: '',
    nda: false,
    reCaptchaResponseToken: '',
    reloadRecaptcha: true
  }

  constructor(props) {
    super(props)
    this.state = this.defaultState
  }

  componentDidMount() {
    document.addEventListener('click', this.onGlobalClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onGlobalClick)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.numberPrefix.length !== 0 ||
      this.state.number.length !== 0
    ) {
      this.fullPhoneNumberInputRef.value = `${this.state.numberPrefix} ${
        this.state.number
      }`
    } else {
      this.fullPhoneNumberInputRef.value = null
      this.fullPhoneNumberInputRef.placeholder = 'Phone number'
    }
    this.state.phoneInputFocused && this.phoneNumberPrefixInputRef.focus()
  }

  verifyCallback = recaptchaToken => {
    this.setState({ reCaptchaResponseToken: recaptchaToken })
  }

  renderRecaptcha = () => {
    if(this.state.reloadRecaptcha) {
      return (
        <ReCaptcha
          ref={r => this.recaptchaRef = r}
          sitekey={this.props.reCaptchaSiteKey}
          action="action_name"
          verifyCallback={this.verifyCallback}
        />
      )
    } else {
      return null;
    }
  }

  onGlobalClick = e => {
    const id = e.target.id

    if (id !== 'contact-phone-prefix' && id !== 'contact-phone-number') {
      this.setState({
        isDummyPhoneInputVisible: true,
        isPhoneInputVisible: false,
      })
    }
  }

  onPhoneInputChange = e => {
    const { id, value } = e.target

    switch (id) {
      case 'contact-phone-prefix':
        this.setState({ numberPrefix: value })
        break
      case 'contact-phone-number':
        this.setState({ number: value, phoneInputFocused: false })
        break
      default:
        break
    }
  }

  render() {
    const {
      onCloseFormClick,
      isOpen,
      isStatic,
      mailingUrl,
    } = this.props

    return (
      <div className="contact-layer-form d-none d-md-block">
        <div className="container">
          <div className="row">
            <div
              className={`${
                isStatic ? '' : isOpen ? 'slideUp' : 'slideDown'
              } contact-form-area col-md-12 offset-md-0 col-lg-8 offset-lg-2`}
            >
              <div className="card card-block contact-form-card">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <form
                      id="contactform-bottom"
                      ref={r => (this.formRef = r)}
                      className="contact-form form-horizontal"
                      action="#"
                      onSubmit={event => {
                        this.setState({ current: this.STATE_SENDING, reloadRecaptcha: false })
                        event.preventDefault()
                        const model = {
                          'g-recaptcha-response': this.state.reCaptchaResponseToken,
                          category: this.state.category,
                          message: this.state.message,
                          email: this.state.email,
                          'phone-prefix': this.state.numberPrefix,
                          'phone-number': this.state.number,
                          nda: this.state.nda,
                        }
                        axios
                          .post(mailingUrl, model)
                          .then(() => {
                            this.setState({
                              email: '',
                              number: '',
                              numberPrefix: '',
                              nda: false,
                              current: this.STATE_SENT,
                              reloadRecaptcha: true
                            })
                            this.formRef.reset()
                          })
                          .catch(() => {
                            this.setState({
                              current: this.STATE_ERROR,
                            })
                          })
                      }}
                    >
                      <fieldset>
                        <p className="lead text-center text-muted">
                          Let us know, and we'll answer within 1-2 business days
                        </p>

                        <div className="form-group">
                          <select
                            className="custom-select custom-select-lg"
                            id="contact-category"
                            name="category"
                            tabIndex="1"
                            required
                            onChange={e =>
                              this.setState({ category: e.target.value })
                            }
                            defaultValue=""
                          >
                            <option value="" disabled hidden>
                              I'm interested in...
                            </option>
                            <option value={Category.MobileApps}>
                              Mobile apps
                            </option>
                            <option value={Category.WebApps}>
                              Web development
                            </option>
                            <option value={Category.Outsourcing}>
                              IT outsourcing
                            </option>
                            <option value={Category.FreeConsultation}>
                              Request free consultation
                            </option>
                            <option value={Category.Other}>...other</option>
                          </select>
                        </div>

                        <div className="form-group">
                          <textarea
                            onChange={e =>
                              this.setState({
                                message: e.target.value,
                                phoneInputFocused: false,
                              })
                            }
                            className="form-control form-control-lg"
                            rows="4"
                            name="message"
                            id="contact-message"
                            placeholder="Message"
                            tabIndex="2"
                            required
                            style={{ resize: 'none' }}
                          />
                        </div>
                        <div className="form-group form-check mx-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="contact-nda"
                            name="nda"
                            tabIndex="3"
                            onChange={e =>
                              this.setState(prevProps => ({
                                nda: !prevProps.nda,
                              }))
                            }
                          />
                          <label
                            className="form-check-label text-muted"
                            htmlFor="contact-nda"
                          >
                            I'd like to receive a Non-Disclosure Agreement draft
                          </label>
                        </div>
                        <div className="collapse" id="collapse-contact-form" />
                        <div className="form-group">
                          <input
                            onChange={e =>
                              this.setState({
                                email: e.target.value,
                                phoneInputFocused: false,
                              })
                            }
                            type="email"
                            name="email"
                            id="contact-email"
                            className="form-control form-control-lg"
                            placeholder="E-mail"
                            tabIndex="4"
                            required
                            value={this.state.email}
                          />
                        </div>

                        <div
                          id="contact-phone-merged"
                          style={
                            this.state.isDummyPhoneInputVisible
                              ? { display: 'block' }
                              : { display: 'none' }
                          }
                        >
                          <div className="form-group">
                            <input
                              id="contact-phone-merged-input"
                              ref={r => (this.fullPhoneNumberInputRef = r)}
                              onFocus={() => {
                                this.setState({
                                  isDummyPhoneInputVisible: false,
                                  isPhoneInputVisible: true,
                                  phoneInputFocused: true,
                                })
                              }}
                              type="text"
                              autoComplete="off"
                              className="form-control form-control-lg"
                              placeholder="Phone number"
                              tabIndex="5"
                            />
                          </div>
                        </div>

                        <div
                          id="contact-phone"
                          style={
                            this.state.isPhoneInputVisible
                              ? { display: 'block' }
                              : { display: 'none' }
                          }
                        >
                          <div className="row">
                            <div className="col-3">
                              <div className="form-group">
                                <input
                                  id="contact-phone-prefix"
                                  ref={r =>
                                    (this.phoneNumberPrefixInputRef = r)
                                  }
                                  onChange={this.onPhoneInputChange}
                                  type="text"
                                  autoComplete="off"
                                  name="phone-prefix"
                                  className="form-control form-control-lg"
                                  placeholder="Prefix"
                                  tabIndex="6"
                                />
                              </div>
                            </div>
                            <div className="col-9">
                              <div className="form-group">
                                <input
                                  id="contact-phone-number"
                                  onChange={this.onPhoneInputChange}
                                  type="text"
                                  name="phone-number"
                                  autoComplete="off"
                                  className="form-control form-control-lg"
                                  placeholder="Phone number"
                                  tabIndex="7"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              {this.renderRecaptcha()}
                              <small>
                                This site is protected by reCAPTCHA and the
                                Google{' '}
                                <a href="https://policies.google.com/privacy">
                                  Privacy Policy
                                </a>{' '}
                                and{' '}
                                <a href="https://policies.google.com/terms">
                                  Terms of Service
                                </a>{' '}
                                apply.
                              </small>
                            </div>
                          </div>
                          <div className="col-md-6 text-right">
                            <button
                              type="submit"
                              name="send_button"
                              className="btn btn-lg btn-outline-primary"
                              tabIndex="8"
                            >
                              {this.state.current === this.STATE_SENDING && (
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  style={{ marginRight: '0.5rem' }}
                                />
                              )}
                              {this.state.current === this.STATE_READY && (
                                <FontAwesomeIcon
                                  icon={faPaperPlane}
                                  style={{ marginRight: '0.5rem' }}
                                />
                              )}
                              {this.state.current === this.STATE_SENT && (
                                <FontAwesomeIcon
                                  style={{ marginRight: '0.5rem' }}
                                  icon={faCheck}
                                />
                              )}
                              {this.state.current}
                            </button>
                            {!isStatic && (
                              <p style={{ marginTop: '20px' }}>
                                <small>
                                  Don't like forms?{' '}
                                  <button
                                    className="button-like-link"
                                    onClick={onCloseFormClick}
                                  >
                                    Show contact details.
                                  </button>
                                </small>
                              </p>
                            )}
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
                <div className={`clearfix ${isStatic ? '' : 'fake-height'}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactForm

export const Category = {
  MobileApps: 'mobileapps',
  WebApps: 'webapps',
  Outsourcing: 'outsourcing',
  FreeConsultation: 'free-consultation',
  Other: 'other',
}

export const scrollToForm = (category, offsetY, duration) => {
  smoothScroll('#contact-partial', {
    offset: offsetY || 0,
    duration: duration || 1000,
  })

  if (category) {
    document.getElementById('contact-category').value = category
    document.getElementById('contact-message').focus()
  }
}
