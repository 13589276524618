import React from 'react'
import {Category, scrollToForm} from "../../contact/ContactForm"

const ServiceWebCta = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="lead text-left text-sm-right">
            <h3 className="seo-span seo-strong text-muted">
              Custom web app development Poland
            </h3>
            <br />
            <span className="text-service">
              High quality software with&nbsp;
              <strong className="span-regular">offshore pricing</strong>
            </span>
            <br />
            <a
              href="#contact-partial"
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.WebApps, 0, 500)
              }}
              className="btn btn-outline-info my-2"
              id="custom-web-app-talk-btn"
            >
              Check our offer!
            </a>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="seo-p">
            Our <h4 className="seo-span">developers</h4> are extremely competent
            and have vast experience in creating&nbsp;
            <h3 className="seo-span">custom web applications</h3> for our
            clients from all over the world. We offer back-end modernization and
            cloud connection. Furthermore, our team can upgrade existing
            front-ends, which results in increasing the software performance.
            Zaven’s <h4 className="seo-span">developers</h4> are always
            effective, cost-efficient and client-oriented.
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebCta
