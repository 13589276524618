import React from 'react'
import Divider from '../common/Divider'
import JumbotronInfoPack from './JumbotronInfoPack'
import JumbotronFeatures from './JumbotronFeatures'

const ServiceJumbotron = ({
  colorAccentClass,
  image,
  title,
  description,
  features,
  children,
  infoPackUrl,  
}) => {
  return (
    <section className={`bg-dark ${colorAccentClass}`}>
      <div className="container">
        <div className="row">
          <div className="d-none d-md-block col-md-4">
            <img src={image} alt={title} className="services-image" />
          </div>
          <div className="col-md-8">
            <article className="page-article">
              <p className="seo-h3">Services</p>
              <h1 className="text-white">{title}</h1>
              <div className="lead">{description}</div>
              <hr className="spacer" />
              <JumbotronInfoPack infoPackUrl={infoPackUrl} />
              <Divider spacingLg rainbow fullWidth />
              {features ? <JumbotronFeatures listOfFeatures={features} /> : {}}
              {children}
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceJumbotron
