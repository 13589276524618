import React from 'react'

const MvpReasons = () => (
  <section className='section bg-faded'>
    <div className='container'>
      <h2 className='text-center mt-2'>
        Equip your startup with full-stack engineering
      </h2>
      <div className='card-group card-group-transparent my-2 mvp-reasons'>
        <div className='card'>
          <div className='card-body'>
            Rapid design prototyping
            <br />
            <em className='text-muted'>
              <abbr title='Minimal Viable Product'>MVP</abbr> shipped in 12-16 weeks
            </em>
          </div>
        </div>
        <div className='card'>
          <div className='card-body'>
            Full team support
            <br />
            <em className='text-muted'>
              analyst, PM, programmers, designers, testers
            </em>
          </div>
        </div>
        <div className='card'>
          <div className='card-body'>
            User-friendly and beautiful UI/UX
            <br />
            <em className='text-muted'>
              designed by our&nbsp;
              <span className='text-nowrap'>in-house</span> design agency
            </em>
          </div>
        </div>
        <div className='card'>
          <div className='card-body'>
            <h3 className='seo-strong m-0'>Agile software development</h3>
            <br />
            <em className='text-muted'>managed with SCRUM principles</em>
          </div>
        </div>
        <div className='card'>
          <div className='card-body'>
            Long-term support
            <br />
            <em className='text-muted'>
              continue product development after MVP period
            </em>
          </div>
        </div>
        <div className='card'>
          <div className='card-body'>
            Remote-friendly management
            <br />
            <em className='text-muted'>
              for
              <h3 className='seo-span'>outsourced development</h3> without
              barriers
            </em>
          </div>
        </div>
      </div>   
    </div>
  </section>
)

export default MvpReasons