import React from 'react'
import {Category, scrollToForm} from "../../contact/ContactForm"

const ServiceWebRequirementCta = () => (
  <section className='service-web bg-dark py-5'>
    <div className='container'>
      <div className='row'>
        <div className='col-md-8 offset-md-2'>
          <div className='lead text-center'>
            <strong>
              A fully-functional web application in just in 4 weeks?&nbsp;
            </strong>
            <br />
            <div className='d-inline text-muted'>
              Our <h3 className='seo-span'>web app design and development</h3>
              process is much quicker because our&nbsp;
              <strong className='seo-regular'>Polish</strong>&nbsp;
              <h4 className='seo-span'>web development team</h4> works in an
              effective and agile way.
            </div>
            <br />
            <a
              role='button'
              href='#contact-partial'
              data-form-category='other'
              className='btn btn-info block-xs m-3'
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.WebApps, 0, 500)
              }}
            >
              Discuss your requirement
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebRequirementCta