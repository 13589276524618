import React from 'react'
import { Link } from 'gatsby'

const ServiceWebTags = () => (
  <section className='service-web bg-faded py-5'>
    <div className='container'>
      <div className='row mb-5'>
        <div className='col-sm-12 col-md-4'>
          <h2 className='text-sm-center text-md-right'>
            <span className='text-muted seo-regular'>Web development</span>
            <br />
            <strong>software team</strong>
          </h2>
        </div>
        <div className='col-sm-12 col-md-8'>
          <p>
            We always make sure to assign people that are best suited to your
            project’s needs, because we believe that a well-matched team is a
            guarantee of success. Every project group consists of web
            developers, designers, usability experts and front-end
            professionals. If you want to learn more,
            <Link to='/about/team'>meet our team</Link>.
          </p>
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-12 col-md-4'>
          <h2 className='text-sm-center text-md-right'>
            <span className='text-muted seo-regular'>Web app</span>
            <br />
            enterprise solutions
          </h2>
        </div>
        <div className='col-sm-12 col-md-8'>
          <div className='seo-p'>
            Zaven’s
            <h2 className='seo-span'>web application development services</h2>
            help you achieve your marketing goals no matter what industry you
            work in: e-learning, ERP, business process automation, social media,
            e-commerce, games, innovations, healthcare or lifestyle.
          </div>
          <ul className='list-unstyled list-inline list-badges'>
            <li>
              <span className='badge badge-light'>.NET</span>
            </li>
            <li>
              <span className='badge badge-light'>.NET MVC</span>
            </li>
            <li>
              <span className='badge badge-light'>Ruby on Rails</span>
            </li>
            <li>
              <span className='badge badge-light'>HTML5</span>
            </li>
            <li>
              <span className='badge badge-light'>ExpressJS</span>
            </li>
            <li>
              <span className='badge badge-light'>Zend</span>
            </li>
            <li>
              <span className='badge badge-light'>ASO.NET</span>
            </li>
            <li>
              <span className='badge badge-light'>Angular JS</span>
            </li>
            <li>
              <span className='badge badge-light'>Prototype testing</span>
            </li>
            <li>
              <span className='badge badge-light'>QA testing</span>
            </li>
            <li>
              <span className='badge badge-light'>Responsive web design</span>
            </li>
            <li>
              <span className='badge badge-light'>Support & Maintenance</span>
            </li>
            <li>
              <span className='badge badge-light'>Static websites</span>
            </li>
            <li>
              <span className='badge badge-light'>
                Mobile-friendly websites
              </span>
            </li>
            <li>
              <span className='badge badge-light'>Service integrations</span>
            </li>
          </ul>
          <div className='clearfix' />
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-12 col-md-4' />
        <div className='col-sm-12 col-md-8' />
      </div>
    </div>
  </section>
)


export default ServiceWebTags