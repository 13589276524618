import React from 'react'
import axios from 'axios'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faSpinner} from '@fortawesome/pro-solid-svg-icons'

class JumbotronInfoPack extends React.Component {
  STATE_READY = 'Get infopack'
  STATE_SENDING = 'Sending...'
  STATE_SENT = 'Infopack sent'
  STATE_ERROR = 'Try again'

  constructor(props) {
    super(props)
    this.state = {
      email: null,
      current: this.STATE_READY,
    }
  }

  render() {
    const { infoPackUrl } = this.props

    return (
      <div className="infopack">
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-8">
            <p className="text-muted">
              If you would like to know more about our services, please leave us
              your email so we can send you our infopack.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-8">
            <form
              ref={r => (this.formRef = r)}
              action="#"
              method="post"
              name="infopack_form"
              id="infopack-form"
              onSubmit={event => {
                this.setState({ current: this.STATE_SENDING })
                event.preventDefault()
                axios
                  .post(infoPackUrl, { email: this.state.email })
                  .then(() => {
                    this.setState({
                      email: null,
                      current: this.STATE_SENT,
                    })
                    this.formRef.reset()
                  })
                  .catch(() => {
                    this.setState({
                      current: this.STATE_ERROR,
                    })
                  })
              }}
            >
              <div className="input-group">
                <input
                  onChange={e => this.setState({ email: e.target.value })}
                  type="email"
                  className="form-control"
                  placeholder="Your e-mail"
                  name="email"
                  required
                />
                <span className="input-group-append">
                  <button
                    className={`btn ${
                      this.state.current === this.STATE_SENT
                        ? 'btn-success'
                        : 'btn-primary'
                    }`}
                    name="send_button"
                    type="submit"
                  >
                    {this.state.current === this.STATE_SENDING && (
                      <FontAwesomeIcon
                        style={{ marginRight: '0.5rem' }}
                        icon={faSpinner}
                        spin
                      />
                    )}
                    {this.state.current === this.STATE_SENT && (
                      <FontAwesomeIcon
                        style={{ marginRight: '0.5rem' }}
                        icon={faCheck}
                      />
                    )}
                    {this.state.current}
                  </button>
                </span>
              </div>
              <div className="form-group form-check mt-2">
                <input
                  id="infopack-form-gdpr-consent"
                  className="form-check-input"
                  name="gdpr_consent"
                  type="checkbox"
                  required
                />
                <label
                  className="form-check-label small text-muted"
                  htmlFor="infopack-form-gdpr-consent"
                >
                  I agree to receive an infopack from Zaven Sp. z.o.o. The
                  consent is voluntary and I can withdraw it any time, stopping
                  further processing of my data.
                </label>
              </div>
              {this.state.current === this.STATE_ERROR && (
                <p
                  id="form-feedback-invalid"
                  className="mt-1 text-sm text-danger"
                >
                  <em>Please make sure your email address is valid</em>
                </p>
              )}
              {this.state.current === this.STATE_SENT && (
                <p
                  id="form-feedback-success"
                  className="mt-1 text-sm text-muted"
                >
                  <em>
                    You can check your e-mail now. If you have difficulties
                    finding it, you may want to look in your SPAM directory.
                  </em>
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default JumbotronInfoPack
