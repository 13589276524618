import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faClock,
  faCubes,
  faEye,
  faPaintBrush,
  faQuestionCircle,
  faRepeat,
  faRocket,
  faSearch,
  faTablet
} from '@fortawesome/pro-light-svg-icons'


const JumbotronFeatures = ({listOfFeatures}) => {

  const renderFeatures = (features) => {
    return features.map((feature, index) => (
      <div key={index} className='col-sm-6'>
        <div className='media feature'>
          <div className='feature-icon mr-3'>
            <FontAwesomeIcon className='fa-fw' size='2x' icon={getIcon(feature.icon)}/>
          </div>
          <div className='media-body'>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        </div>
      </div>
    ))
  }

  const getIcon = (iconName) => {
    switch (iconName) {
      case 'faQuestionCircle': return faQuestionCircle
      case 'faBrush': return faPaintBrush
      case 'faTablet': return faTablet
      case 'faRepeat': return faRepeat
      case 'faRocket': return faRocket
      case 'faSearch': return faSearch
      case 'faClock': return faClock
      case 'faCubes': return faCubes
      case 'faEye': return faEye
      default: return faQuestionCircle
    }
  }

  return (
    <div className='row'>
      {renderFeatures(listOfFeatures)}
    </div>
  )
}

export default JumbotronFeatures
