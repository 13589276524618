import React from 'react';
import Helmet from 'react-helmet';
import Footer from '../components/common/Footer'
import BackToTop from '../components/common/BackToTop';
import Gdpr from '../components/common/Gdpr';
import { StaticQuery, graphql } from 'gatsby';

import favicon from '../assets/images/favicon.ico';

const getCookie = (name) => {
  if(typeof window !== 'undefined') {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=')
      return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
  }
}

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          layout_info: dataJson {
            capital
            krs_sad
            krs
            vat
            googleplus
            facebook
            linkedin
            github
            ...Contact
          }
          layout_site: site {
            siteMetadata {
              title
              version
            }
          }
        }
      `}
      render={data => (
        <div id="root_top">
          <Helmet
            title={data.layout_site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content:
                  'We’re a process-driven software development company from Poland. We help businesses to connect with their audiences using the high-end web and mobile apps.',
              },
              {
                name: 'keywords',
                content:
                  'mobile development, software company Poland, best mobile app design, swift iOS developer, software near-sourcing, outsourcing offshore, android apps, ios apps',
              },
            ]}
            link={[{ rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }]}
          />
          {children}
          <Footer
            companyDetails={data.layout_info}
            websiteVersion={data.layout_site.siteMetadata.version}
            newsletterUrl={`${process.env.GATSBY_MAILING_URL}/api/newsletter`}
          />
          <BackToTop />
          {getCookie("zavenGdpr") ? null : <Gdpr />}
        </div>
      )}
    />
  );
};

export default Layout;
